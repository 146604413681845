<template>
  <Layout class="main-container">
    <!--    搜索块-->
    <Form
      ref="SearchForm"
      class="search-form-container"
      :model="params"
      label-position="left"
      :label-width="80"
    >
      <div class="search-form-container__content">
        <Row :gutter="16">
          <Col span="6">
            <FormItem label="订单编号:" prop="OrderCode">
              <Input placeholder="请输入订单编号" v-model="params.OrderCode" />
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="会员编号:" prop="MemberCode">
              <Input placeholder="请输入会员编号" v-model="params.MemberCode" />
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="物流单号:" prop="ShippingCode">
              <Input
                placeholder="请输入物流单号"
                v-model="params.ShippingCode"
              />
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="收货人:" prop="ReceiveSearchText">
              <Input
                placeholder="请输入收货人名称/电话/地址"
                v-model="params.ReceiveSearchText"
              />
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="16">
          <!-- <Col span="6">
          <FormItem label="订单状态:"
                    prop="OrderStatus">
            <Select clearable
                    v-model="params.OrderStatus"
                    placeholder="订单状态">
              <Option v-for="(item,index) in orderStatusEnum"
                      :key="index"
                      :value="item.ID">{{ item.CN }}</Option>
            </Select>
          </FormItem>
          </Col> -->
          <Col span="6">
            <FormItem label="订单类型:" prop="OrderType">
              <Select
                clearable
                v-model="params.OrderType"
                placeholder="订单类型"
              >
                <Option
                  v-for="(item, index) in orderTypeEnum"
                  :key="index"
                  :value="item.ID"
                >
                  {{ item.CN }}
                </Option>
              </Select>
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="商品名称:" prop="ProductName">
              <Input
                placeholder="请输入商品名称"
                v-model="params.ProductName"
              />
            </FormItem>
          </Col>
        </Row>
      </div>
      <Row type="flex" justify="space-between" align="bottom" class="mt5">
        <Col span="12" style="text-align: left"></Col>
        <Col span="12" style="text-align: right">
          <span
            class="search-form-container__arrow_btn"
            @click="$handleHiddenSearch($el)"
          >
            收起
            <Icon type="ios-arrow-up" />
          </span>
          <Button
            class="ml2 search-form-container__button"
            type="primary"
            ghost
            icon="ios-search"
            @click="handleSearch"
          >
            搜索
          </Button>
          <Button
            class="ml2 search-form-container__button"
            @click="handleReset"
          >
            重置
          </Button>
        </Col>
      </Row>
    </Form>
    <!--    table-->
    <Table
      class="mt10 main-table"
      :loading="tableLoading"
      :height="tableHeight"
      :row-class-name="RowClassName"
      ref="dataTable"
      :columns="columns"
      :data="resObj.DataList"
      highlight-row
      @on-sort-change="onSortChange"
      size="small"
      stripe
    >
      <template slot="ShippingCode" slot-scope="{ row }">
        <span v-if="row.ShippingCode">{{ row.ShippingCode || "未发货" }}</span>
      </template>
      <template slot="MemberView" slot-scope="{ row }">
        <span
          style="color: green; cursor: pointer"
          @click="handleOpenUser(row.MemberCode)"
        >
          {{ row.MemberCode }}
        </span>
      </template>
      <template slot="OrderStatus" slot-scope="{ row }">
        <Tag
          type="border"
          :color="
            row.OrderStatus === 10
              ? 'green'
              : row.OrderStatus === 20
              ? 'volcano'
              : row.OrderStatus === 30
              ? 'magenta'
              : row.OrderStatus === 40
              ? 'red'
              : row.OrderStatus === 50
              ? 'orange'
              : 'default'
          "
        >
          {{ row.StatusCN }}
        </Tag>
      </template>
      <template slot="Action" slot-scope="{ row }">
        <Button
          size="small"
          type="primary"
          v-if="$canAction(['Order_Search'])"
          @click="openDrawer(row, false)"
        >
          查看
        </Button>
      </template>
      <template slot="FreightTypeCN" slot-scope="{ row }">
        <Tag
          type="border"
          :color="
            row.FreightType === 10
              ? 'red'
              : row.FreightType === 20
              ? 'purple'
              : 'blue'
          "
        >
          {{ row.FreightTypeCN }}
        </Tag>
      </template>
    </Table>
    <!--    分页-->
    <Row class="mt10">
      <Col span="24" class="flex-end">
        <Page
          :page-size-opts="[40, 60, 80, 100]"
          :page-size="40"
          :total="resObj.TotalCount"
          :current="resObj.CurrentPage"
          @on-page-size-change="handleChangePageSize"
          @on-change="handleChangePage"
          size="small"
          show-elevator
          show-sizer
          show-total
        />
      </Col>
    </Row>
    <detail-drawer ref="DetailDrawer" />
    <user-profile-drawer ref="userDrawer" />
  </Layout>
</template>

<script>
import api from "@/api";
import Layout from "@/components/layout/index.vue";
import bizEnum from "@/libs/bizEnum";
// import { mapActions } from 'vuex';
import DetailDrawer from "./components/detail-drawer.vue";
import UserProfileDrawer from "../member/components/member-drawer.vue";
export default {
  name: "RefundedOrder",
  data() {
    return {
      tableHeight: 0,
      tableLoading: false,
      isSearchMore: false,
      params: {
        OrderCode: "",
        MemberCode: "",
        ReceiveSearchText: "",
        ReceiveName: "",
        ReceivePhone: "",
        ShippingCode: "",
        ProductName: "",
        OrderStatus: 70,
        OrderType: "",
        Page: 1,
        PageSize: 40,
        SortName: "OrderDate",
        SortOrder: "Desc",
      },
      columns: [
        {
          title: "订单编号",
          key: "OrderCode",
          minWidth: 175,
          sortable: "custom",
        },
        // {
        //   title: "会员编号",
        //   key: "MemberCode",
        //   slot: "MemberView",
        //   align: "center",
        //   minWidth: 90,
        //   sortable: "custom",
        // },
        {
          title: "总金额",
          key: "TotalAmount",
          minWidth: 80,
          sortable: "custom",
        },
        {
          title: "商品名",
          key: "ProductName",
          minWidth: 130,
          sortable: "custom",
        },
        {
          title: "商品数量",
          key: "ProductCount",
          minWidth: 90,
          sortable: "custom",
        },
        { title: "收货姓名", key: "ReceiveName", minWidth: 95 },
        { title: "收货人电话", key: "ReceivePhone", minWidth: 95 },
        {
          title: "物流单号",
          key: "ShippingCode",
          slot: "ShippingCode",
          minWidth: 100,
          sortable: "custom",
          ellipsis: true,
        },
        {
          title: "物流公司",
          key: "ExpressName",
          align: "center",
          minWidth: 80,
        },
        // {
        //   title: "运费方式",
        //   key: "FreightTypeCN",
        //   align: "center",
        //   slot: "FreightTypeCN",
        //   minWidth: 80,
        //   sortable: "custom",
        // },
        {
          title: "下单时间",
          key: "OrderDate",
          align: "center",
          minWidth: 135,
          sortable: "custom",
        },
      ],
      resObj: {
        CurrentPage: 1,
        TotalCount: 0,
        TotalPage: 0,
        DataList: [],
      },
    };
  },
  created() {
    if (this.$route.meta.tableActionWidth) {
      this.columns.push({
        title: "操作",
        key: "action",
        slot: "Action",
        width: this.$route.meta.tableActionWidth,
        fixed: "right",
        align: "right",
      });
    }
    this.loadOrderList();
  },
  mounted() {
    const self = this;
    self.$nextTick(() => {
      self.tableHeight = self.$getTableHeight("main-container");
    });
    window.onresize = function () {
      self.$nextTick(() => {
        self.tableHeight = self.$getTableHeight("main-container");
      });
    };
    document.addEventListener("keydown", this.keydownFun);
    this.$bus.$on("refreshPage", () => {
      if (this.$route.name === this.$options.name) {
        this.params && (this.params = this.$options.data().params);
        this.handleSearch();
      }
    });
  },
  activated() {
    document.addEventListener("keydown", this.keydownFun);
  },
  deactivated() {
    document.removeEventListener("keydown", this.keydownFun);
  },
  methods: {
    keydownFun() {
      const self = this;
      var e = event || window.event;
      if (self.$route.name === self.$options.name && e && e.keyCode === 13) {
        // enter 键
        self.handleSearch();
      }
    },
    onSortChange(result) {
      this.params.SortName =
        result.key === "FreightTypeCN" ? "FreightType" : result.key;
      this.params.SortOrder = result.order !== "normal" ? result.order : "desc";
      this.loadOrderList();
    },
    handleCancel(row) {
      const self = this;
      this.$Modal.confirm({
        title: "删除确认",
        loading: true,
        content: "确定要删除该订单吗?",
        okText: "删除",
        async onOk() {
          try {
            await api.DeleteOrder({ ids: row.ID });
            const index = self.resObj.DataList.findIndex(
              (item) => item.ID === row.ID
            );
            if (index !== -1) {
              self.resObj.DataList[index].OrderStatus = -99;
              self.resObj.DataList[index].StatusCN = "已删除";
            }
            self.$Message.success("删除成功");
            self.$Modal.remove();
          } catch (error) {
            this.$Notice.error({ title: "错误", desc: error });
            this.$Modal.remove();
          }
        },
      });
    },
    openDrawer(row, isEdit) {
      this.$refs.DetailDrawer.obj = row;
      this.$refs.DetailDrawer.isShow = true;
      this.$refs.DetailDrawer.isEdit = isEdit;
    },
    handleChangePageSize(pagesize) {
      this.params.Page = 1;
      this.params.PageSize = pagesize;
      this.loadOrderList();
    },
    handleChangePage(page) {
      this.params.Page = page;
      this.loadOrderList();
    },
    handleSearch() {
      this.params.Page = 1;
      this.loadOrderList();
    },
    handleReset() {
      this.$refs.SearchForm.resetFields();
      this.loadOrderList();
    },
    async loadOrderList() {
      this.tableLoading = true;
      try {
        const res = await api.GetOrderList(this.params);
        this.resObj = res.Data;
        this.tableLoading = false;
      } catch (err) {
        this.tableLoading = false;
        this.$Notice.error({ title: "错误", desc: err });
      }
    },
    async handleOpenUser(MemberID) {
      try {
        const res = await api.GetMemberByCode({
          code: MemberID,
        });
        if (res.Status === 100) {
          this.$refs.userDrawer.obj = res.Data;
          this.$refs.userDrawer.isShow = true;
        } else {
          this.$Message.error(res.Msg);
        }
      } catch (error) {
        this.$Notice.error({
          title: "错误",
          desc: error,
        });
        this.tableLoading = false;
        // TODO: 记录错误日志
      }
    },
    // 选中行样式
    RowClassName() {
      return "";
    },
  },
  computed: {
    orderStatusEnum() {
      return bizEnum.OrderStatusEnum;
    },
    orderTypeEnum() {
      return bizEnum.OrderTypeEnum;
    },
  },
  components: { DetailDrawer, UserProfileDrawer, Layout },
};
</script>

<style lang="less">
.ivu-table .demo-table-error-row td {
  background-color: #ffd591 !important;
  color: rgb(0, 0, 0);
}
</style>
